import {
  Button,
  Card,
  Col,
  Input,
  message,
  Modal,
  Row,
  Table,
  Tooltip,
} from "antd"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  ListTouchless_touchlessList,
  ListTouchless_touchlessList_pages_content,
} from "./types/ListTouchless"
import {
  PlusOutlined,
  DeleteOutlined,
  DiffOutlined,
  CheckOutlined,
  EditOutlined,
} from "@ant-design/icons"
import { gql, useMutation } from "@apollo/client"
import {
  UpdateTouchlessPages as UpdateTouchlessPagesMutation,
  UpdateTouchlessPagesVariables,
} from "./types/UpdateTouchlessPages"
import { LIST_TOUCHLESS_QUERY } from "./UpdateTouchless"
import styled from "styled-components"
import { ListActions } from "../util/page"
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd"
import TouchlessContentItem from "../components/TouchlessContentItem"
import { reorder } from "../util/util"
import { v4 as uuid } from "uuid"

const UPDATE_TOUCHLESS_PAGES_MUTATION = gql`
  mutation UpdateTouchlessPages($input: UpdateTouchlessPagesInput) {
    updateTouchlessPages(input: $input) {
      id
    }
  }
`

interface Props {
  projectID: string
  touchless: ListTouchless_touchlessList
}

const UpdateTouchlessPages: React.FC<Props> = ({ touchless, projectID }) => {
  const [loadedTouchless, setLoadedTouchless] = useState<string | null>(null)
  const [showNameModal, setShowNameModal] = useState(false)
  const [name, setName] = useState("")
  const [selectedPage, setSelectedPage] = useState<number | null>(null)

  const [showRenameModal, setShowRenameModal] = useState(false)
  const [renamePage, setRenamePage] = useState<number | null>(null)

  useEffect(() => {
    if (touchless.id !== loadedTouchless) {
      setLoadedTouchless(touchless.id)
      setSelectedPage(null)

      // DEBUG
      if (touchless.pages.length > 0) {
        setSelectedPage(0)
        setContentPages(
          touchless.pages[0].content.map((page, index) => ({
            index,
            page,
            uid: uuid(),
          }))
        )
      }
    }
  }, [touchless])

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deletingPage, setDeletingPage] = useState<number | null>(null)

  const [updateTouchlessPages, updateTouchlessPagesMutation] = useMutation<
    UpdateTouchlessPagesMutation,
    UpdateTouchlessPagesVariables
  >(UPDATE_TOUCHLESS_PAGES_MUTATION, {
    refetchQueries: [{ query: LIST_TOUCHLESS_QUERY, variables: { projectID } }],
    onCompleted: () => {
      message.success("Pages erfolgreich gespeichert")
    },
    onError: () => {
      message.error("Ein Fehler ist aufgetreten.")
    },
  })

  const indexedPages = useMemo(
    () =>
      touchless.pages.map((page, index) => ({
        page,
        index,
      })),
    [touchless]
  )

  // TODO: Reordering is broken here for some reason
  const [contentPages, setContentPages] = useState<
    Array<{
      index: number
      page: Omit<ListTouchless_touchlessList_pages_content, "__typename">
      uid: string
    }>
  >([])

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result
    if (!destination) return
    setContentPages(prev => reorder(prev, source.index, destination.index))
  }

  useEffect(() => {
    if (selectedPage == null) {
      setContentPages([])
      return
    }

    const page = touchless.pages[selectedPage]
    setContentPages(
      page?.content.map((page, index) => ({ index, page, uid: uuid() }))
    )
  }, [selectedPage])

  const onSaveContentPages = useCallback(() => {
    if (selectedPage == null) return

    const toSave = indexedPages.map(({ page, index }) =>
      index !== selectedPage
        ? page
        : { name: page.name, content: contentPages.map(it => it.page) }
    )

    updateTouchlessPages({
      variables: {
        input: {
          id: touchless.id,
          pages: toSave,
        },
      },
    })
  }, [indexedPages, selectedPage, touchless, contentPages])

  return (
    <Container>
      <Row gutter={24}>
        <Col span={16}>
          <h2 style={{ marginTop: "1rem", marginBottom: "2rem" }}>
            Menüpunkte für {touchless.name}:
          </h2>
        </Col>

        <Col
          span={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => setShowNameModal(true)}
          >
            Neuer Menüpunkt
          </Button>

          <Modal
            title="Menüpunkt löschen"
            visible={showDeleteModal}
            okText="Menüpunkt löschen"
            cancelText="Abbrechen"
            okButtonProps={{
              loading: updateTouchlessPagesMutation.loading,
              danger: true,
            }}
            cancelButtonProps={{
              disabled: updateTouchlessPagesMutation.loading,
            }}
            onCancel={() => {
              setShowDeleteModal(false)
            }}
            onOk={() => {
              if (deletingPage == null) return

              const updatedPages = touchless.pages.filter(
                (_, index) => index !== deletingPage
              )

              try {
                updateTouchlessPages({
                  variables: {
                    input: {
                      id: touchless.id,
                      pages: updatedPages,
                    },
                  },
                })

                setShowDeleteModal(false)
                setDeletingPage(null)
              } catch {
                message.error("Ein Fehler ist aufgetreten.")
              }
            }}
          >
            Sind Sie sicher, dass Sie den Menüpunkt "
            {touchless.pages[deletingPage ?? -1]?.name}" löschen möchten?
          </Modal>

          <Modal
            title="Neuer Menüpunkt"
            visible={showNameModal}
            okText="Menüpunkt erstellen"
            cancelText="Abbrechen"
            okButtonProps={{ loading: updateTouchlessPagesMutation.loading }}
            cancelButtonProps={{
              disabled: updateTouchlessPagesMutation.loading,
            }}
            onCancel={() => {
              setShowNameModal(false)
              setName("")
            }}
            onOk={() => {
              if (!name) return

              try {
                updateTouchlessPages({
                  variables: {
                    input: {
                      id: touchless.id,
                      pages: [
                        ...touchless.pages,
                        {
                          content: [],
                          name,
                        },
                      ],
                    },
                  },
                })

                setShowNameModal(false)
                setName("")
              } catch {
                message.error("Ein Fehler ist aufgetreten.")
              }
            }}
          >
            <h3>Geben Sie einen Namen für den Menüpunkt ein:</h3>

            <Input
              value={name}
              onChange={e => setName(e.target.value)}
              style={{ width: "80%" }}
            />
          </Modal>

          <Modal
            title="Menüpunkt umbenennen"
            visible={showRenameModal}
            okText="Menüpunkt umbenennen"
            cancelText="Abbrechen"
            okButtonProps={{ loading: updateTouchlessPagesMutation.loading }}
            cancelButtonProps={{
              disabled: updateTouchlessPagesMutation.loading,
            }}
            onCancel={() => {
              setShowRenameModal(false)
              setRenamePage(null)
              setName("")
            }}
            onOk={() => {
              if (!name || renamePage == null) return

              const pages = touchless.pages.map((it, index) =>
                index !== renamePage
                  ? it
                  : {
                      name,
                      content: it.content,
                    }
              )

              try {
                updateTouchlessPages({
                  variables: {
                    input: {
                      id: touchless.id,
                      pages,
                    },
                  },
                })

                setShowRenameModal(false)
                setRenamePage(null)
                setName("")
              } catch {
                message.error("Ein Fehler ist aufgetreten.")
              }
            }}
          >
            <h3>Geben Sie einen neuen Namen für den Menüpunkt ein:</h3>

            <Input
              value={name}
              onChange={e => setName(e.target.value)}
              style={{ width: "80%" }}
            />
          </Modal>
        </Col>
      </Row>

      <TableContainer>
        <Table
          rowKey="index"
          dataSource={indexedPages}
          pagination={false}
          bordered
          rowClassName={({ index }: typeof indexedPages[number]) =>
            selectedPage === index ? "selected-row" : ""
          }
        >
          <Table.Column
            title="Name"
            key={0}
            render={({ page }: typeof indexedPages[number]) => page.name}
          />

          <Table.Column
            key={5}
            title="Aktionen"
            width={105}
            fixed="right"
            render={({ index }: typeof indexedPages[number]) => (
              <ListActions>
                <Tooltip
                  title={
                    selectedPage === index
                      ? `Ausgewählt`
                      : "Menüpunkt bearbeiten"
                  }
                >
                  {selectedPage === index ? (
                    <Button icon={<CheckOutlined />} type="primary" disabled />
                  ) : (
                    <Button
                      icon={<DiffOutlined />}
                      type="primary"
                      onClick={() => {
                        setSelectedPage(index)
                      }}
                    />
                  )}
                </Tooltip>

                <Tooltip title="Umbenennen">
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => {
                      setName(indexedPages[index].page.name)
                      setRenamePage(index)
                      setShowRenameModal(true)
                    }}
                  />
                </Tooltip>

                <Tooltip title="Löschen">
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => {
                      setDeletingPage(index)
                      setShowDeleteModal(true)
                    }}
                  />
                </Tooltip>
              </ListActions>
            )}
          />
        </Table>
      </TableContainer>

      <PagesContainer>
        {selectedPage != null && (
          <DragDropContext onDragEnd={res => onDragEnd(res)}>
            <Droppable
              droppableId="content-page"
              type="content-page-droppable"
              direction="horizontal"
            >
              {provided => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ display: "flex", overflowY: "auto" }}
                >
                  {contentPages.map((p, idx) => (
                    <Draggable key={p.uid} draggableId={p.uid} index={idx}>
                      {provided => (
                        <div
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          style={{
                            marginRight: "1rem",
                            marginBottom: "1rem",
                            ...provided.draggableProps.style,
                          }}
                        >
                          <TouchlessContentItem
                            dragHandle={provided.dragHandleProps}
                            value={{
                              contentType:
                                (p.page.contentType as "video" | "image") ??
                                "image",
                              image: p.page.image ?? undefined,
                              video: p.page.video ?? undefined,
                            }}
                            onChange={updated =>
                              setContentPages(prev =>
                                prev.map(it =>
                                  it.uid === p.uid
                                    ? {
                                        ...it,
                                        page: {
                                          contentType:
                                            (updated.contentType as
                                              | "video"
                                              | "image") ?? "image",
                                          image: updated.image ?? "",
                                          video: updated.video ?? "",
                                        },
                                      }
                                    : it
                                )
                              )
                            }
                            onDelete={() =>
                              setContentPages(prev =>
                                prev.filter(it => it.uid !== p.uid)
                              )
                            }
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}

                  {provided.placeholder}

                  <AddItemContainer>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        setContentPages(prev => [
                          ...prev,
                          {
                            index: prev.length,
                            page: {
                              contentType: "image",
                              video: null,
                              image: null,
                            },
                            uid: uuid(),
                          },
                        ])
                      }}
                    >
                      Neue Content Page
                    </Button>
                  </AddItemContainer>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </PagesContainer>

      <Col>
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginTop: "1rem" }}
          loading={updateTouchlessPagesMutation.loading}
          onClick={() => onSaveContentPages()}
        >
          Content Pages Speichern
        </Button>
      </Col>
    </Container>
  )
}

const PagesContainer = styled.div`
  margin-top: 2rem;
`

const AddItemContainer = styled(Card)`
  height: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const TableContainer = styled.div`
  .selected-row {
    background: #f0f0f0;
    border: 1px solid #333;
  }
`

const Container = styled.div`
  width: 100%;
  margin-top: 2rem;
`

export default UpdateTouchlessPages
