import { Button, Form, Input, message, Upload } from "antd"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { useForm } from "antd/lib/form/Form"
import TextArea from "antd/lib/input/TextArea"
import Axios, { AxiosError } from "axios"
import { CMS_URL, token } from ".."
import { CloudUploadOutlined, LoadingOutlined } from "@ant-design/icons"
import { Helmet } from "react-helmet"

const TouchlessCustomValues: React.FC<{
  onSave?: (data: any) => void
  loading?: boolean
  initialValue?: any
}> = ({ onSave = () => {}, loading = false, initialValue }) => {
  const [form] = useForm()

  useEffect(() => {
    if (!initialValue) return

    form.setFieldsValue({
      ..._.omit(
        initialValue,
        "headingFontFamily",
        "headingFontSize",
        "textFontFamily",
        "textFontSize"
      ),
    })

    const headingFontParts = initialValue.headingFontFamily?.split("::") ?? []
    if (headingFontParts.length === 2)
      setHeadingFont({
        fontFamily: headingFontParts[0],
        cssUrl: headingFontParts[1],
        size: initialValue.headingFontSize,
      })
    else setHeadingFont({ size: initialValue.headingFontSize })

    const textFontParts = initialValue.textFontFamily?.split("::") ?? []
    if (textFontParts.length === 2)
      setNormalFont({
        fontFamily: textFontParts[0],
        cssUrl: textFontParts[1],
        size: initialValue.textFontSize,
      })
    else setNormalFont({ size: initialValue.textFontSize })
  }, [initialValue, form])

  const [normalFont, setNormalFont] = useState<Font | null>(null)
  const [headingFont, setHeadingFont] = useState<Font | null>(null)

  const onSaveInner = () => {
    const values = {
      ...form.getFieldsValue(),
      headingFontFamily: headingFont?.fontFamily
        ? `${headingFont.fontFamily}::${headingFont.cssUrl}`
        : undefined,
      headingFontSize: headingFont?.size,
      textFontFamily: normalFont?.fontFamily
        ? `${normalFont.fontFamily}::${normalFont.cssUrl}`
        : undefined,
      textFontSize: normalFont?.size,
    }

    onSave(values)
  }

  return (
    <Container>
      <Form form={form} layout="vertical" onFinish={values => onSave(values)}>
        <h2 className="heading">Texte und Farben</h2>

        <div className="custom-settings">
          <div className="row">
            <h3>Allgemein</h3>

            <ColorItem
              label="Hintergrundfarbe"
              name="backgroundColor"
              initial={initialValue}
              def="#FFFFFF"
            />

            <ColorItem
              label="Überschriftenfarbe"
              name="headingColor"
              initial={initialValue}
              def="#000000"
            />

            <ColorItem
              label="Textfarbe"
              name="textColor"
              def="#000000"
              initial={initialValue}
            />

            <ColorItem
              label="Hinweisfarbe"
              name="hintColor"
              def="#E5E7EB"
              initial={initialValue}
            />
          </div>

          <div className="row">
            <h3>Menü-Button</h3>

            <ColorItem
              label="Hintergrundfarbe"
              name="menuButtonColor"
              def="#3B82F6"
              initial={initialValue}
            />

            <ColorItem
              label="Textfarbe"
              name="menuButtonTextColor"
              initial={initialValue}
              def="#FFFFFF"
            />
          </div>

          <div className="row">
            <h3>Schließen-Button</h3>

            <Form.Item label="Text" name="closeButtonText">
              <Input placeholder="Beenden" />
            </Form.Item>

            <ColorItem
              label="Hintergrundfarbe"
              name="closeButtonColor"
              def="#E5E7EB"
              initial={initialValue}
            />

            <ColorItem
              initial={initialValue}
              label="Textfarbe"
              name="closeButtonTextColor"
              def="#000000"
            />
          </div>

          <div className="row">
            <h3>Swipe-Fläche</h3>

            <Form.Item label="Text" name="swipeHintText">
              <TextArea
                style={{ width: "30ch" }}
                autoSize
                placeholder="Swipen Sie auf der Fläche, um weitere Inhalte zu sehen."
              />
            </Form.Item>

            <ColorItem
              initial={initialValue}
              label="Hintergrundfarbe"
              name="swipeAreaColor"
              def="#E5E7EB"
            />

            <ColorItem
              initial={initialValue}
              label="Iconfarbe"
              name="swipeIconColor"
              def="#4B5563"
            />
          </div>

          <div className="row">
            <h3>Zurück-Button</h3>
            <ColorItem
              initial={initialValue}
              label="Farbe"
              name="backButtonColor"
              def="#000000"
            />
          </div>

          <div className="row">
            <h3>Basket-Button</h3>

            <ColorItem
              initial={initialValue}
              label="Hintergrundfarbe"
              name="bundleButtonColor"
              def="#16A34A"
            />

            <ColorItem
              initial={initialValue}
              label="Iconfarbe"
              name="bundleButtonTextColor"
              def="#FFFFFF"
            />
          </div>

          <div className="row">
            <h3>E-Mail Dialog</h3>

            <Form.Item label="Text" name="emailInfoText">
              <TextArea
                style={{ width: "30ch" }}
                autoSize
                placeholder="Geben Sie Ihre E-Mail-Adresse ein, um die hier gezeigten Inhalte per E-Mail zu erhalten."
              />
            </Form.Item>

            <Form.Item label="Hinweis" name="emailHintText">
              <TextArea
                autoSize
                style={{ width: "30ch" }}
                placeholder="Ihre E-Mail wird nicht gespeichert und nur zum Versenden der Inhalte verwendet."
              />
            </Form.Item>

            <Form.Item
              label="Eingabefeld Platzhalter"
              name="emailPlaceholderText"
            >
              <Input placeholder="Ihre E-Mail-Adresse" />
            </Form.Item>
          </div>

          <div className="row">
            <h3>"E-Mail senden"-Button</h3>

            <Form.Item label="Text" name="emailSendButtonText">
              <Input placeholder="Inhalte Senden" />
            </Form.Item>

            <ColorItem
              initial={initialValue}
              label="Hintergrundfarbe"
              name="emailSendButtonColor"
              def="#3B82F6"
            />

            <ColorItem
              initial={initialValue}
              label="Textfarbe"
              name="emailSendButtonTextColor"
              def="#FFFFFF"
            />
          </div>

          <div className="row">
            <h3>"E-Mail versendet"-Dialog</h3>

            <Form.Item label="Text" name="emailSentText">
              <TextArea
                style={{ width: "30ch" }}
                autoSize
                placeholder="Vielen Dank! Sie erhalten die Inhalte in Kürze unter Ihrer angegebenen E-Mail."
              />
            </Form.Item>

            <ColorItem
              initial={initialValue}
              label="Textfarbe"
              name="emailSentTextColor"
              def="#16A34A"
            />
          </div>

          <div className="row">
            <h3>"Verbindung beendet"-Screen</h3>

            <Form.Item label="Text" name="connectionClosedText">
              <TextArea
                style={{ width: "30ch" }}
                autoSize
                placeholder="Die Verbindung wurde beendet."
              />
            </Form.Item>
          </div>

          <div className="row">
            <h3>Schriftart Normaler Text</h3>
            <FontUploader
              value={normalFont}
              onChange={v => setNormalFont(v)}
              defaultSize={16}
            />
          </div>

          <div className="row">
            <h3>Schriftart Überschriften</h3>
            <FontUploader
              value={headingFont}
              onChange={v => setHeadingFont(v)}
              defaultSize={20}
            />
          </div>
        </div>
      </Form>

      <Button
        type="primary"
        htmlType="submit"
        style={{ marginBottom: "1rem" }}
        loading={loading}
        onClick={() => onSaveInner()}
      >
        Texte und Farben speichern
      </Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  input {
    width: 30ch !important;
  }

  .color-input input {
    width: 12ch !important;
  }

  textarea {
    width: 30ch !important;
  }

  .heading {
    margin-bottom: 2rem;
  }

  .custom-settings {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
    align-items: flex-start;

    > .row {
      display: flex;
      gap: 1rem;
      flex-basis: calc(50% - 1rem);

      padding: 1.5rem 1.5rem 0 1.5rem;
      border: 1px solid #e5e7eb;
      border-radius: 6px;

      position: relative;

      > h3 {
        position: absolute;
        top: -0.7rem;
        padding: 0 0.25rem;
        left: 1rem;
        background: #fff;
        font-weight: bold;
      }
    }
  }
`

export interface Font {
  fontFamily?: string
  cssUrl?: string
  size?: number
}

const FontUploader: React.FC<{
  value: Font | null
  onChange: (v: Font | null) => void
  defaultSize: number
}> = ({ value, onChange, defaultSize }) => {
  const [loading, setLoading] = useState(false)

  const onFontUpload = async (file: File) => {
    setLoading(true)
    const formData = new FormData()
    formData.append("file", file)

    try {
      const res = await Axios.post(`${CMS_URL}/fonts`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      onChange({
        ...value,
        fontFamily: res.data.fontFamily,
        cssUrl: res.data.css,
      })

      setLoading(false)
    } catch (err) {
      setLoading(false)
      const res = (err as AxiosError).response

      if (res?.status === 413) {
        message.error("Die Schriftdatei ist zu groß. (Maximal 32MB)")
        return
      }

      if (res?.status === 400 && res?.data.cause === "invalid file type") {
        message.error(
          "Die Schriftdatei hatte ein ungültiges Format. Die folgenden Formate sind erlaubt: .ttf, .woff, .woff2, .svg, .otf",
          10
        )
        return
      }

      message.error("Ein unbekannter Fehler ist aufgetreten.")
    }
  }

  return (
    <FontUploadContainer
      size={value?.size || defaultSize}
      fontFamily={value?.fontFamily}
    >
      <Helmet>
        {value?.cssUrl && <link rel="stylesheet" href={value.cssUrl} />}
      </Helmet>

      <div className="font-dropzone">
        <Upload.Dragger
          showUploadList={false}
          disabled={loading}
          beforeUpload={v => {
            onFontUpload(v)
            return false
          }}
        >
          {!loading ? (
            <FontUploadIcon>
              <CloudUploadOutlined />
            </FontUploadIcon>
          ) : (
            <FontUploadIcon>
              <LoadingOutlined spin />
            </FontUploadIcon>
          )}
        </Upload.Dragger>
      </div>

      <div className="font-preview">
        <p className="size-label">Größe</p>

        <Input
          className="size-input"
          type="number"
          value={value?.size || undefined}
          onChange={v => onChange({ ...value, size: v.target.valueAsNumber })}
          placeholder={`${defaultSize}`}
        />

        <p className="font-preview-text">{value?.fontFamily || `Standard`}</p>
      </div>
    </FontUploadContainer>
  )
}

const FontUploadIcon = styled.div`
  color: #40a9ff;
  font-size: 48px;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
`

const FontUploadContainer = styled.div<{ size: number; fontFamily?: string }>`
  margin-bottom: 1.5rem;
  display: flex;
  gap: 1rem;

  > .font-dropzone {
    width: 200px;
    height: 100px;
  }

  > .font-preview {
    .size-label {
      margin-bottom: 0.2rem;
    }

    .size-input {
      width: 8ch !important;
    }

    > .font-preview-text {
      padding: 0.8rem 0 0 0.6rem;
      margin-bottom: 0;
      font-size: ${props => props.size}px;

      ${props =>
        props.fontFamily &&
        css`
          font-family: ${props.fontFamily};
        `}
    }
  }
`

const ColorItem: React.FC<{
  name: string
  label: string
  def: string
  initial?: any
}> = ({ name, label, def, initial }) => {
  const [colorVal, setColorVal] = useState("")
  useEffect(() => {
    if (initial == null) return
    setColorVal(initial?.[name] ?? "")
  }, [initial])

  return (
    <Form.Item
      {...{ name, label }}
      rules={[
        {
          pattern: /#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
          message: "Muss Hex-Code sein",
        },
      ]}
    >
      <Input
        className="color-input"
        placeholder={def}
        value={colorVal}
        onChange={ev => setColorVal(ev.target.value)}
        addonAfter={<ColorPreview color={colorVal || def} />}
      />
    </Form.Item>
  )
}

const ColorPreview = styled.div<{ color: string }>`
  width: 30px;
  height: 30px;
  margin-left: -11px;
  margin-right: -11px;
  background-color: ${props => props.color};
`

export default TouchlessCustomValues
